<template>
  <div class="tab-buttons" :class="getShadowClass">
    <div class="tab-buttons-contents">
      <a class="tab-buttons-logo" href="/" v-if="!isScreenSmAndDown">
        <v-img class="logo" :src="require('../../assets/images/pc/logo_alpha.png')" :width="'90'" />
      </a>
      <a class="tab-buttons-news">
        <div class="tab-buttons-btn" @click="goToNews">
          <img :src="getTabImageNews" />
        </div>
        <div class="tab-buttons-line" />
      </a>
      <a class="tab-buttons-about">
        <div class="tab-buttons-btn" @click="goToAbout">
          <img :src="getTabImageAbout" />
        </div>
        <div class="tab-buttons-line" />
      </a>
      <a class="tab-buttons-business">
        <div class="tab-buttons-btn" text @click="goToBusiness">
          <img :src="getTabImageBusiness" />
        </div>
        <div class="tab-buttons-line" />
      </a>
      <a class="tab-buttons-recruit">
        <div class="tab-buttons-btn" text @click="goToRecruit">
          <img :src="getTabImageRecruit" />
        </div>
        <div class="tab-buttons-line" />
      </a>
      <a class="tab-buttons-recruit">
        <div class="tab-buttons-btn" text @click="goToContact">
          <img :src="getTabImageContact" />
        </div>
        <div class="tab-buttons-line" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      scrollY: null,
    };
  },
  computed: {
    ...mapState(['viewPage']),
    isXs() {
      return this.$vuetify.breakpoint.xs;
    },
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getTabImageNews() {
      const path = this.isXs ? 'sp/header_news_sp.png' : 'pc/titlemenu_news.png';
      return require(`../../assets/images/${path}`);
    },
    getTabImageAbout() {
      const path = this.isXs ? 'sp/header_about_sp.png' : 'pc/titlemenu_about.png';
      return require(`../../assets/images/${path}`);
    },
    getTabImageBusiness() {
      const path = this.isXs ? 'sp/header_business_sp.png' : 'pc/titlemenu_business.png';
      return require(`../../assets/images/${path}`);
    },
    getTabImageRecruit() {
      const path = this.isXs ? 'sp/header_recruit_sp.png' : 'pc/titlemenu_recruit.png';
      return require(`../../assets/images/${path}`);
    },
    getTabImageContact() {
      const path = this.isXs ? 'sp/header_contact_sp.png' : 'pc/titlemenu_contact.png';
      return require(`../../assets/images/${path}`);
    },
    getShadowClass() {
      if (this.$route.name == 'Top') {
        if (this.isScreenSmAndDown) {
          return {
            shadow: window.innerWidth / this.scrollY < 1.78,
          };
        }
        return {
          shadow: window.innerWidth / this.scrollY < 2.45,
        };
      } else {
        if (this.isScreenSmAndDown) {
          return {
            shadow: window.innerWidth / this.scrollY < 3.24,
          };
        }
        return {
          shadow: window.innerWidth / this.scrollY < 3.77,
        };
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions(['setHeader']),
    handleScroll() {
      this.scrollY = window.scrollY;
      // console.log(window.innerWidth, this.scrollY, window.innerWidth / this.scrollY);
    },
    goToNews() {
      if (this.viewPage === 'news') {
        return;
      }
      this.$router.push('/news');
      this.setHeader({ pageName: 'news' });
    },
    goToAbout() {
      if (this.viewPage === 'about') {
        return;
      }
      this.setHeader({ pageName: 'about' });
      this.$router.push('/about');
    },
    goToBusiness() {
      if (this.viewPage === 'business') {
        return;
      }
      this.setHeader({ pageName: 'business' });
      this.$router.push('/business');
    },
    goToRecruit() {
      // window.open('https://en-gage.net/cosmo-japan_saiyo', '_blank');
      // return;
      window.open('https://www.cosmo-japan.net/requirements', '_blank');
      if (this.viewPage === 'recruit') {
        return;
      }
      this.setHeader({ pageName: 'recruit' });
      this.$router.push('/recruit');
    },
    goToContact() {
      if (this.viewPage === 'contact') {
        return;
      }
      this.setHeader({ pageName: 'contact' });
      this.$router.push('/contact');
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-buttons {
  width: 100%;
  height: 60px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: -2px;
  z-index: 10;

  @media screen and (max-width: 960px) {
    padding-top: 20px;
  }

  &.shadow {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }

  &-contents {
    display: flex;
    justify-content: flex-end;
  }

  &-logo {
    position: fixed;
    top: 0;
    left: 0;
    padding: 4px;
  }

  &-news,
  &-about,
  &-business,
  &-recruit {
    width: 25vw;
    max-width: 150px;
    text-align: center;

    @media screen and (min-width: 960px) {
      width: 170px;
    }
  }

  &-line {
    width: 24vw;
    max-width: 150px;
    min-height: 4px;
    background-image: url('../../assets/images/pc/line.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 0 auto;
    margin-top: -6px;

    @media screen and (min-width: 768px) {
      min-height: 6px;
    }

    @media screen and (min-width: 960px) {
      width: 150px;
      height: 6px;
    }
  }
}
</style>
