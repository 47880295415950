<template>
  <div class="footer">
    <div class="footer-container">
      <div class="footer-container__menu">
        <div class="c-menu">
          <div class="c-menu__box">
            <div class="c-menu__title">企業情報</div>
            <div class="c-menu__list">
              <div class="c-menu__items">
                <div class="c-menu__item"><a @click="goTo('about', 'profile')">企業概要</a></div>
                <div class="c-menu__item"><a @click="goTo('about', 'history')">沿革</a></div>
              </div>
            </div>
            <div class="c-menu__title">事業内容</div>
            <div class="c-menu__list">
              <div class="c-menu__items">
                <div class="c-menu__item"><a @click="goTo('business', 'game')">ゲーム開発事業</a></div>
                <!-- <div class="c-menu__item"><a @click="goTo('business', 'creative')">クリエイティブ事業</a></div> -->
                <div class="c-menu__item"><a @click="goTo('business', 'develop')">受託事業</a></div>
                <div class="c-menu__item"><a @click="goTo('business', 'school')">ITスクール事業</a></div>
                <div class="c-menu__item"><a @click="goTo('business', 'ses')">SES事業</a></div>
              </div>
            </div>
          </div>
          <div class="c-menu__box">
            <div class="c-menu__title">ニュース/実績</div>
            <div class="c-menu__list">
              <div class="c-menu__items">
                <div class="c-menu__item"><a @click="goTo('news', 'news')">ニュース</a></div>
              </div>
            </div>
            <div class="c-menu__title">採用情報</div>
            <div class="c-menu__list">
              <div class="c-menu__items">
                <div class="c-menu__item"><a @click="goTo('recruit', 'message')">メッセージ</a></div>
                <div class="c-menu__item"><a @click="goTo('recruit', 'recruirements')">募集概要</a></div>
                <div class="c-menu__item"><a @click="goToRecruit">エンゲージ採用ページ(外部サイト)</a></div>
              </div>
            </div>
          </div>
          <div class="c-menu__box">
            <div class="c-menu__title">お問い合わせ</div>
            <div class="c-menu__list">
              <div class="c-menu__items">
                <div class="c-menu__item"><a @click="goTo('contact', 'contact')">お問い合わせフォーム</a></div>
              </div>
            </div>
            <div class="c-menu__title">関連企業</div>
            <div class="c-menu__items">
              <div class="c-menu__item"><a @click="goToMBK">メディウスバンドコスモロギー株式会社</a></div>
              <div class="c-menu__item"><a @click="goToST">株式会社Start IT</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-privacy">
      <a class="footer-privacy-text" href="./privacy">PRIVACY POLICY</a>
    </div>
    <div>
      <div class="footer-privacy-img">
        <img src="/10825267_01_EN.png" />
      </div>
    </div>
    <div class="footer-privacy-copyright">© 2019 Cosmo Japan Co., Ltd. All Rights Reserved.</div>
  </div>
</template>

<script>
export default {
  methods: {
    goTo(name, id) {
      this.$emit('goTo');
      if (this.$route.fullPath == `/${name}#${id}`) {
        const target = document.getElementById(id);
        const rect = target.getBoundingClientRect().top;
        const pos = rect + window.pageYOffset - 80;
        window.scrollTo({
          top: pos,
          behavior: 'smooth',
        });
      } else {
        this.$router.push({ path: name, hash: `#${id}` });
      }
    },
    goToRecruit() {
      window.open('https://en-gage.net/cosmo-japan_saiyo', '_blank');
    },
    goToMBK() {
      window.open('https://m-b-kosmo.net/', '_blank');
    },
    goToST() {
      window.open('https://start-it.tokyo/', '_blank');
    },
  },
};
</script>

<style lang="scss">
.footer {
  background-color: #e8e8e8;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1.2em;
  }

  &-container {
    padding: 20px 10vw;
  }

  &-privacy {
    padding: 8px;

    @media screen and (min-width: 960px) {
      padding: 24px;
    }

    &-text {
      color: #000000de !important;
    }

    &-copyright {
      margin-bottom: 20px;
    }
  }

  &-privacy-img {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: 20px;
    > img {
      width: 120px;
    }
  }
}

.c-menu {
  text-align: left;

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: center;
    gap: 30px;
  }

  &__box {
    @media screen and (min-width: 768px) {
      min-width: 20%;
    }
  }

  &__title {
    margin-top: 20px;
    font-weight: bold;
    font-size: 1.2rem;
  }

  &__item {
    padding: 2px;

    @media screen and (max-width: 768px) {
      font-size: 1.2em;
    }
  }

  a:visited {
    color: inherit !important;
  }

  a {
    color: inherit !important;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
