<template>
  <v-app id="app">
    <SpMenu
      ref="spmenu"
      class="spmenu"
      v-if="isShowSpMenu"
      @goToTop="goToTop"
      @goToNews="goToNews"
      @goToAbout="goToAbout"
      @goToBusiness="goToBusiness"
      @goToDevelop="goToDevelop"
      @goToSchool="goToSchool"
      @goToRecruit="goToRecruit"
      @goToContact="goToContact"
    />
    <TabButtons
      v-if="isShowTabButtons"
      @goToNews="goToNews"
      @goToAbout="goToAbout"
      @goToBusiness="goToBusiness"
      @goToDevelop="goToDevelop"
      @goToSchool="goToSchool"
      @goToSes="goToSes"
      @goToRecruit="goToRecruit"
      @goToContact="goToContact"
    />
    <Header />
    <transition mode="out-in">
      <router-view @goToDevelop="goToDevelop" @goToSchool="goToSchool" @goToSes="goToSes" />
    </transition>
    <Footer @goTo="goToFooterAction" />
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Header from '@/components/parts/Header';
import Footer from '@/components/parts/Footer';
import SpMenu from '@/components/parts/SpMenu';
import TabButtons from '@/components/parts/TabButtons';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    SpMenu,
    TabButtons,
  },
  computed: {
    ...mapState(['viewPage', 'showTabButtons']),
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isShowSpMenu() {
      return !this.isShowTabButtons;
    },
    isShowTabButtons() {
      if (!this.showTabButtons) {
        return false;
      }
      return this.viewPage !== 'privacy' && this.$vuetify.breakpoint.width >= 768;
    },
  },
  methods: {
    ...mapActions(['setHeader']),
    goToFooterAction() {
      if (this.$refs.spmenu) {
        this.$refs.spmenu.goToFooterAction();
      }
    },
    goToTop() {
      document.location = '/';
    },
    goToNews() {
      if (this.viewPage === 'news') {
        return;
      }
      this.$router.push('/news');
      this.setHeader({ pageName: 'news' });
    },
    goToAbout() {
      if (this.viewPage === 'about') {
        return;
      }
      this.setHeader({ pageName: 'about' });
      this.$router.push('/about');
    },
    goToBusiness() {
      if (this.viewPage === 'business') {
        return;
      }
      this.setHeader({ pageName: 'business' });
      this.$router.push('/business');
    },
    goToDevelop() {
      window.open('https://www.cosmo-japan.net/projects/', '_blank');
      return;
    },
    goToSchool() {
      window.open('https://www.cosmo-japan.net/school/', '_blank');
      return;
    },
    goToSes() {
      window.open('https://www.cosmo-japan.net/ses/', '_blank');
      return;
    },
    goToRecruit() {
      if (this.viewPage === 'recruit') {
        return;
      }
      window.open('https://www.cosmo-japan.net/requirements', '_blank');
      this.setHeader({ pageName: 'recruit' });
      this.$router.push('/recruit');
      // window.open('https://en-gage.net/cosmo-japan_saiyo', '_blank');
      // return;
    },
    goToContact() {
      if (this.viewPage === 'contact') {
        return;
      }
      this.setHeader({ pageName: 'contact' });
      this.$router.push('/contact');
    },
  },
};
</script>

<style lang="scss">
* {
  margin: 0px;
  padding: 0px;
  ::v-deep .v-application a {
    color: #000000de !important;
  }
}

.spmenu {
  z-index: 10000;
}

#app {
  font-family: 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', Meiryo, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgba(0, 0, 0, 0);

  @media screen and (min-width: 320px) {
    font-size: 9px;
  }
  @media screen and (min-width: 375px) {
    font-size: 11px;
  }
  @media screen and (min-width: 425px) {
    font-size: 12px;
  }
  @media screen and (min-width: 768px) {
    font-size: 14px;
    margin-top: 60px;
  }
  @media screen and (min-width: 960px) {
    font-size: 16px;
  }

  ul,
  li {
    padding-left: 0;
  }
}

.v-enter {
  transform: translate(-100px, 0);
  opacity: 0;
}
.v-enter-to {
  opacity: 1;
}
.v-enter-active {
  transition: all 1s 0s ease;
}
.v-leave {
  transform: translate(0, 0);
  opacity: 1;
}
.v-leave-to {
  transform: translate(100px, 0);
  opacity: 0;
}
.v-leave-active {
  transition: all 0.5s 0s ease;
}
</style>
