<template>
  <div class="header">
    <transition name="header-top">
      <v-img
        class="header-img"
        v-show="!isShowPrivacy && viewHeader === 'top'"
        :src="getHeaderImageTop"
        :aspect-ratio="aspectTop / 9"
      />
    </transition>
    <div class="sp-typeing" v-if="isMobile">
      <div class="typeing" data-speed="70">If you can't make what you want to make,</div>
      <div class="typeing2" data-speed="70">the work of an engineer is not interesting!</div>
    </div>
    <transition name="header">
      <v-img
        class="header-img"
        v-show="!isShowPrivacy && viewHeader === 'news'"
        :src="require('../../assets/images/pc/header_news.png')"
        :aspect-ratio="aspectOther / 9"
      />
    </transition>
    <transition name="header">
      <v-img
        class="header-img"
        v-show="!isShowPrivacy && viewHeader === 'about'"
        :src="require('../../assets/images/pc/header_about.png')"
        :aspect-ratio="aspectOther / 9"
      />
    </transition>
    <transition name="header">
      <v-img
        class="header-img"
        v-show="!isShowPrivacy && viewHeader === 'business'"
        :src="require('../../assets/images/pc/header_business.png')"
        :aspect-ratio="aspectOther / 9"
      />
    </transition>
    <transition name="header">
      <v-img
        class="header-img"
        v-show="!isShowPrivacy && viewHeader === 'recruit'"
        :src="require('../../assets/images/pc/header_recruit.png')"
        :aspect-ratio="aspectOther / 9"
      />
    </transition>
    <transition name="header">
      <v-img
        class="header-img"
        v-show="!isShowPrivacy && viewHeader === 'contact'"
        :src="require('../../assets/images/pc/header_contact.png')"
        :aspect-ratio="aspectOther / 9"
      >
        <div class="header-img-text">
          <v-img
            v-show="!isShowPrivacy && viewHeader === 'contact'"
            :src="require('../../assets/images/pc/font-contact.png')"
          />
        </div>
      </v-img>
    </transition>
    <transition name="header">
      <div>
        <v-img
          class="header-img"
          v-show="!isShowPrivacy && viewHeader === 'entry'"
          :src="require('../../assets/images/pc/header_entry.png')"
          :aspect-ratio="aspectOther / 9"
        >
          <div class="header-img-text">
            <v-img
              v-show="!isShowPrivacy && viewHeader === 'entry'"
              :src="require('../../assets/images/pc/font-entry.png')"
            />
          </div>
        </v-img>
      </div>
    </transition>
    <div class="header-logo" :style="styleHeader"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      isShowSecondPart: false,
    };
  },
  computed: {
    ...mapState(['viewPage', 'viewHeader']),
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isMobile() {
      // widthが640px以下の時だけ表示するやつ
      return this.$vuetify.breakpoint.width < 640;
    },
    isShowPrivacy() {
      return this.viewPage === 'privacy';
    },
    aspectTop() {
      return this.isScreenSmAndDown ? 16 : 22;
    },
    aspectOther() {
      return this.isScreenSmAndDown ? 26 : 34;
    },
    styleHeader() {
      const val = this.viewPage === 'top' ? this.aspectTop : this.aspectOther;
      const height = (this.$vuetify.breakpoint.width / val) * 9;
      const logoPixel = this.isScreenSmAndDown ? '70px' : '120px';
      return {
        height: this.isShowPrivacy ? logoPixel : `${height}px`,
      };
    },
    getHeaderImageTop() {
      const path = this.isScreenSmAndDown ? 'sp/top_header_sp.png' : 'pc/header_top.png';
      return require(`../../assets/images/${path}`);
    },
  },
  mounted() {
    // タイピング部分
    const typeTarget = document.querySelectorAll('.typeing');
    const typeTarget2 = document.querySelectorAll('.typeing2');

    let options = {
      rootMargin: '0px',
      threshold: 0.5,
    };

    let callback = (entries /*observer*/) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0.5 && entry.target.classList.contains('active') == false) {
          let typeContent = entry.target.textContent;
          let typeSprit = typeContent.split('');
          let typeSpeed = entry.target.getAttribute('data-speed');
          entry.target.textContent = '';
          entry.target.classList.add('active');

          let typeLength = 0;
          let typeInterval = setInterval(() => {
            if (typeSprit[typeLength] == undefined) {
              clearInterval(typeInterval);
              return false;
            }
            entry.target.textContent += typeSprit[typeLength];
            typeLength++;
          }, typeSpeed);
        }
      });
    };

    let observer = new IntersectionObserver(callback, options);

    setTimeout(() => {
      typeTarget.forEach((e) => {
        observer.observe(e);
      });
    }, 600);
    setTimeout(() => {
      typeTarget2.forEach((e) => {
        observer.observe(e);
      });
    }, 3800);
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  top: 0;
  width: 100vw;

  &-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    &-text {
      width: 60%;
      margin: 0 auto;
      @media screen and (min-width: 960px) {
        width: 40%;
      }
    }
  }

  &-logo {
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.header-top-enter {
  // transform: translate(-100px, 0);
  opacity: 0;
}
.header-top-enter-to {
  opacity: 1;
}
.header-top-enter-active {
  transition: all 1s 0s ease;
}
.header-top-leave {
  transform: translate(0, 0);
  opacity: 1;
}
.header-top-leave-to {
  transform: translate(100px, 0);
  opacity: 0;
}
.header-top-leave-active {
  transition: all 0.5s 0s ease;
}

.header-enter {
  transform: translate(-100px, 0);
  opacity: 0;
}
.header-enter-to {
  opacity: 1;
}
.header-enter-active {
  transition: all 1s 0s ease;
}
.header-leave {
  transform: translate(0, 0);
  opacity: 1;
}
.header-leave-to {
  transform: translate(100px, 0);
  opacity: 0;
}
.header-leave-active {
  transition: all 0.5s 0s ease;
}

.logo {
  @media screen and (min-width: 960px) {
    position: fixed;
    z-index: 100;
  }
}

.sp-typeing {
  position: absolute;
  bottom: 30px;
  text-align: center;
  width: 100%;
  color: white;
  font-weight: bold;
}
.typeing {
  position: absolute;
  bottom: 20px;
  width: 100%;
}
.typeing2 {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.typeing,
.typeing2 {
  opacity: 0;
}

.typeing.active,
.typeing2.active {
  opacity: 1;
}
</style>
